.reactions-wrap {
  bottom: 7rem;
  left: 0;
  width: 100%;
  max-width: 30rem;
  height: 100%;
  min-height: 30rem;
  position: absolute;
  z-index: 10;
}
@media only screen and (max-width: 849px) {
  .reactions-wrap {
    bottom: 10rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 26rem;
  }
}

.satisfaction-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 10px;
}

.heart-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 50px;
}

.haha-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 80px;
}

.wow-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 110px;
}

.sad-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 150px;
}

.clap-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 180px;
}

.tip-animation {
  width: 100%;
  max-width: 100px;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 100px;
}

.heart {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/love.svg);
}

.satisfaction {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/like.svg);
}

.wow {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/wow.svg);
}

.clap {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/clap_hand_animated.gif);
  background-size: contain;
}

.haha {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/haha.svg);
}
.sad {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/sad.svg);
}

.tip {
  position: absolute;
  height: 10%;
  min-height: 15px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-image: url(./icons/icons8-dollar.gif);
  background-size: contain;
}


.ani1 {
  animation: floating-animation 3s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.1s;
}
.ani2 {
  animation: floating-animation2 3s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.5s;
}

.wani1 {
  animation: floating-animation 3s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.1s;
}
.wani2 {
  animation: floating-animation2 2.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.4s;
}

.hani1 {
  animation: floating-animation 3.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.2s;
}
.hani2 {
  animation: floating-animation2 2.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.5s;
}

.lani1 {
  animation: floating-animation 2s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0s;
}
.lani2 {
  animation: floating-animation2 2.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.5s;
}

.cani1 {
  animation: floating-animation 4s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.2s;
}
.cani2 {
  animation: floating-animation2 2s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.7s;
}

.tani1 {
  animation: floating-animation 4s cubic-bezier(0.5, 0.5, 0.5, 0.5);
  animation-delay: 0.2s;
}

//@keyframes floating-animation {
//  0% {
//    opacity:0.5;
//    bottom: 0%;
//    left: 15%;
//  }
//  90% {
//    opacity: 1;
//    bottom: 90%;
//    left: 15%;
//  }
//
//  100% {
//    opacity: 0;
//    bottom: 100%;
//    left: 15%;
//    transform: scale(0.8);
//  }
//}

@keyframes floating-animation {
  0% {
    opacity: 0;
    bottom: 0%;
    left: 10%;
  }
  10% {
    opacity: 1;
    bottom: 10%;
    left: 15%;
  }
  20% {
    opacity: 1;
    bottom: 20%;
    left: 20%;
  }
  30% {
    opacity: 1;
    bottom: 30%;
    left: 15%;
  }
  40% {
    opacity: 1;
    bottom: 40%;
    left: 10%;
  }
  50% {
    opacity: 1;
    bottom: 50%;
    left: 15%;
  }
  60% {
    opacity: 1;
    bottom: 60%;
    left: 20%;
  }
  70% {
    opacity: 1;
    bottom: 70%;
    left: 15%;
  }
  80% {
    opacity: 1;
    bottom: 80%;
    left: 10%;
  }
  90% {
    opacity: 1;
    bottom: 90%;
    left: 15%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 20%;
    transform: scale(0.8);
  }
}

@keyframes floating-animation2 {
  0% {
    opacity: 0;
    bottom: 0%;
    left: 25%;
  }
  10% {
    opacity: 1;
    bottom: 10%;
    left: 10%;
  }
  20% {
    opacity: 1;
    bottom: 20%;
    left: 15%;
  }
  30% {
    opacity: 1;
    bottom: 30%;
    left: 25%;
  }
  40% {
    opacity: 1;
    bottom: 40%;
    left: 30%;
  }
  50% {
    opacity: 1;
    bottom: 50%;
    left: 25%;
  }
  60% {
    opacity: 1;
    bottom: 60%;
    left: 15%;
  }
  70% {
    opacity: 1;
    bottom:70%;
    left: 25%;
  }
  80% {
    opacity: 1;
    bottom: 80%;
    left: 20%;
  }
  90% {
    opacity: 1;
    bottom: 90%;
    left: 15%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 10%;
    transform: scale(0.8);
  }
}
