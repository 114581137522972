
.reaction-counter {
  display: flex;
  background-color: rgba(204, 204, 204, 0.5);
  border-radius: 2.5rem;
  padding: 1rem;
}

.reaction {
  display: flex;
  position: relative;
  text-align: center;
  margin: 0 1rem 0 0;
}

.count {
  right: 0;
  border-radius: 0.5rem;
  background: transparent;
  color: #ffffff;
  padding: 0.4rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
}

