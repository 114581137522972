.notification-hide {
  animation: hideAnimation 0.5s ease-in 1s;
  animation-fill-mode: forwards;
}
@keyframes hideAnimation {
  0% {
    opacity: 1;
    left: 50px;
  }
 70% {
    opacity: 0;
    left: 50px;
  }

  100% {
    opacity: 0;
    left: 50px;
  }
}
