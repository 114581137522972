@font-face {
	font-family: 'Open Sans';
	@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700&display=swap');
}

@font-face {
	font-family: 'Sue Ellen Francisco';
	@import url('https://fonts.googleapis.com/css2?family=Sue+Ellen+Francisco&display=swap');
}

@font-face {
	font-family: 'Covered By Your Grace';
	@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Sue+Ellen+Francisco&display=swap');
}

@font-face {
	font-family: Comfortaa;
	@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');
}


.tooltip { position: relative; }

.tooltip::before {
  content: "\2003" attr(class); /* print em-space with class text */
  text-indent: -3.9em; /* add negative text offset to hide class name */
  display: inline-block;
  position: absolute; bottom: 50%;
  background: #000; color: #FFF; padding: 5px; border-radius: 5px;
  opacity:0; transition:0.3s; overflow:hidden;
  max-width: 50%;
  pointer-events: none;
}

.tooltip:hover::before { opacity:1; bottom: 100%; }

.toolTip {
  font-family: Comfortaa;
}
/*custom scrollbars*/

::-webkit-scrollbar {
  width: 5px;
  margin-left: 20px;
  padding-left: 20px;
}

::-webkit-scrollbar-track {
  background-color: #0F1119;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
